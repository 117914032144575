<template>
	<v-container fluid>
		<v-container>
			<v-card>
                <v-card-title>{{ $t('gdpr.title') }}</v-card-title>
                <v-card-subtitle>{{ $t('gdpr.subtitle') }}</v-card-subtitle>
                <v-card-text class="kktin">
                    <v-row><v-col>
                    <div>
                        Dňa 25.05.2018 vstúpilo do platnosti všeobecné <a href="https://dataprotection.gov.sk/uoou/sk/main-content/nariadenie-gdpr" target="_blank">nariadenie EÚ o ochrane osobných údajov</a>, tiež známe pod označením GDPR. Toto nariadenie spoločne s ustanoveniami Zákona o ochrane osobných údajov a ďalšími všeobecne platnými právnymi predpismi upravuje spracúvanie osobných údajov a ochranu súkromia.
                    </div>
                    </v-col></v-row>
                    <v-row><v-col>
                    <h4>ROZSAH SPRACOVANIA OSOBNÝCH ÚDAJOV</h4><h1></h1>
                    <div>
                        Spoločnosť <b>Sectro s.r.o., IČO: 46507248</b> uchováva a spracúva nasledujúce údaje:
                        <ol>
                            <li>
                                na účely prístupu na chránenú časť webovej stránky sectro.sk, vypracovania a zasielania cenových ponúk a zasielania informácií o novinkách:
                                <br>
                                <i>meno, priezvisko, názov spoločnosti, pozícia, telefón, e-mail</i>
                            </li>
                            <li>
                                na účely vzdelávania a vydávania osobitných oprávnení v zmysle <a href="https://www.minv.sk/?vseobecne-zavazne-pravne-predpisy-1" target="_blank">Zákona o ochrane pred požiarmi</a>
                                <br>
                                <i>titul, meno, priezvisko, dátum narodenia, adresa trvalého bydliska</i>
                            </li>
                            <li>
                                na účely zvýšenia bezpečnosti poskytovaných služieb a aktualizácie portfólia nami ponúkaných produktov:
                                <br>
                                <i>dátum posledného prihlásenia na webovú stránku, aktivita používateľa na produktovej časti webovej stránky</i>
                            </li>
                        </ol>
                    </div>
                    </v-col></v-row>
                    <v-row><v-col>
                    <h4>OCHRANA OSOBNÝCH ÚDAJOV</h4>
                    <div>
                        <p>
                            Našou prioritou je získavať a uchovávať osobné údaje iba v uvedenom rozsahu a na nevyhnutnú dobu. Vaše údaje môžu byť sprístupnené len našim spolupracovníkom, ktorí sa starajú o to, aby boli naše služby ešte lepšie a ktorí majú zmluvne zaistenú povinnosť mlčanlivosti. Pre zaistenie doručovacích služieb využívame služby tretích strán, ktoré poskytujú požadovanú úroveň zabezpečenia ochrany osobných údajov.
                        </p>
                        <p>
                            Naša spoločnosť zaviedla technické a organizačné štandardy za účelom ochrany osobných údajov pred ich zneužitím. Dôvernosť a integrita osobných údajov v elektronickej podobe je zabezpečená kryptografickými algoritmami, prístup k nim je zaznamenávaný v našich systémoch.
                        </p>
                    </div>
                    </v-col></v-row>
                    <v-row><v-col>
                    <h4>PRÁVA DOTKNUTEJ OSOBY</h4>
                    <div>
                        <p>
                            Máte právo požiadať nás o prístup, opravu, doplnenie, obmedzenie spracúvania a vymazanie Vašich osobných údajov. Vašou žiadosťou sa budeme vždy zaoberať a skúmať ju z hľadiska právnej úpravy a uplatniteľných výnimiek. Môžete nás kontaktovať osobne, telefonicky alebo prostredníctvom e-mailu. Aktuálne <router-link to="contact">kontaktné údaje</router-link> nájdete na webovej stránke sectro.sk.
                        </p>
                        <p>
                            Na odhlásenie z odberu informácií o novinkách, ktoré zasielame prostredníctvom e-mailu, môžete použiť odkaz uvedený na konci každej správy.
                        </p>
                    </div>
                    </v-col></v-row>
                </v-card-text>
            </v-card>
		</v-container>
	</v-container>
</template>

<script>
	export default {
		data: () => ({
		}),
	}
</script>

<style scoped>
.kktin {
    text-align: justify;
}
</style>
